import React from "react";
import { Avatar, IconButton, Typography } from "@mui/material";
import Cookies from "universal-cookie";
import removeAccessToken from "../../Helper/RemoveAccessToken/RemoveAccessToken";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import Vammo from "../../Assets/Images/Sidebar/VammoLogoBlack.svg";

function ProfileAvatar(props) {
  const cookies = new Cookies();

  const GetAvatar = () => {
    return (
      <Avatar
        style={{
          height: "27px",
          width: "27px",
          backgroundColor: "#FF977A",
          border: `  1px solid ${
            props.openProfilePopup
              ? ThemeProperties.purple
              : ThemeProperties.white
          }`,
        }}
      >
        <Typography
          sx={{
            display: "flex",
            textAlign: "center",
            color: "#fff",
            fontSize: "14px",
          }}
        >
          {cookies.get("name") === undefined
            ? removeAccessToken()
            : cookies.get("name").charAt(0)}
        </Typography>
      </Avatar>
    );
  };
  
  const GetLogo = () => {
    const fleetID = parseInt(cookies.get("fleetID"))

    switch (fleetID) {
      case 6:
        return <div
          className="profile_Icon" 
        >
          <img 
            src={Vammo} 
            alt="not-found" 
            width={"80px"} 
            style={{
              backgroundColor: props.openProfilePopup ? ThemeProperties.purpleSelected  : "transparent", 
              padding: "5px",
              borderRadius:"4px"
            }}
          />
        </div>
      default:
        return <GetAvatar/>
    }
  };

  return (
    <>
      <IconButton>
        {props.showIcon ? <GetLogo/> : <GetAvatar/>}
      </IconButton>
    </>
  );
}

export default ProfileAvatar;
